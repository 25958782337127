import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import HelpIcon from "@mui/icons-material/Help";
import UnitechHeader from "../image/srfheader.png";
import UnitechFooter from "../image/certificate_footer.png";
import {
  Button,
  Grid,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import { viewsdispatchGuide } from "./guide";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { generatePDF1 } from "./dispatchPrint";

export default function ViewDispatch() {
  const viewsdispatchGuides = viewsdispatchGuide();
  const [instrumentData, setInstrumentsData] = React.useState([]);
  const [combinedData, setCombinedData] = React.useState([]);
  const params = useParams();
  const [state, setState] = React.useState({
    clientName: "",
    address: "",
    dispatchMedium: "",
    deliveryChallanNo: "",
    poNumber: "",
    dcNumber: "",
    dispatchDate: "",
  });
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [challanNo, setChallanNo] = React.useState("");

  const getDispatchIdList = () => {
    let url = BASE_URL;
    let SqlQuery= {"query":`SELECT d.*, s.contactPersonDetails FROM dispatch d LEFT JOIN srfs s ON d.srfsId = s.id WHERE d.id = ${params.id}`}
    axiosWithToken
      .post(url + `dynamic`,SqlQuery)
      .then((res) => {
        let data = res.data[0];
        const contactDetails = data?.contactPersonDetails ? JSON.parse(data.contactPersonDetails) : null;

        setChallanNo(data?.deliveryChallanNo);
        setState((prevState) => ({
          ...prevState,
          clientName: data?.companyName || prevState?.companyName,
          address: data?.address || prevState?.address,
          dispatchMedium: data?.dispatchMedium || prevState?.dispatchMedium, 
          deliveryChallanNo: data?.deliveryChallanNo || prevState?.deliveryChallanNo, 
          instrumentIds: data?.instrumentIds || prevState?.instrumentIds, 
          dispatchDate: data?.dispatchDate || prevState?.dispatchDate, 
          contactPersonName: contactDetails?.contactPersonName || prevState?.contactPersonName, 
          contact: contactDetails?.contact || prevState?.contact, 
          emailId: contactDetails?.emailId || prevState?.emailId, 
        }));

      })
      .catch((error) => console.log(error));
  };

  const getSrfsList = () => {
    let url = BASE_URL;
    let query = {
      query : `SELECT srfs.serviceReqNumber, srfs.jobNumber, srfs.poNumber, srfs.poDate, srfs.receiptDate, srfs.committedDeliveryDate, srfs.dcNumber, srfs.dcDate, srfs.entryDate, srfs.description, srfs.address, clients.id, clients.companyName, GROUP_CONCAT(a.address SEPARATOR ' || ') AS addresses FROM srfs LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(CONCAT(',', srfs.address, ','), ',', ',,'), ',,', ',')) > 0 LEFT JOIN clients ON srfs.clientId = clients.id WHERE srfs.id = ${params.srfId}`
    }
    return axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        let data = res.data[0];
        setState((prevState) => ({
          ...prevState,
          serviceReqNumber: data?.serviceReqNumber || prevState?.serviceReqNumber,
          poNumber: data?.poNumber || prevState?.poNumber,
          dcNumber: data?.dcNumber || prevState?.dcNumber,
          dcDate: data?.dcDate || prevState?.dcDate,
        }));
      })
      .catch((error) => console.log(error));
  };
  const srfInstrumentsList = () => {
    let url = BASE_URL;
    let query =  {
      query : `SELECT srfInstruments.*, COALESCE(NULLIF(srfInstruments.requestedDucName, ''), instruments.instrumentName) AS instrumentName FROM srfInstruments LEFT JOIN instruments ON srfInstruments.instrumentId = instruments.id WHERE srfInstruments.srfsId = ${params.srfId} AND srfInstruments.id IN (${state?.instrumentIds}) AND srfInstruments.status = 1` 
    };

    return axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        setInstrumentsData(res.data);
      })
      .catch((error) => console.log(error));
  };


  useEffect(() => {
    const combinedData = instrumentData.map(({ instrumentName, ranges, lc, serialNumber, DUCID }) => ({
      instrumentName,
      ranges,
      serialNumber,
      DUCID,
      lc,
      quantity: 1
    }));
  
    setCombinedData(combinedData);
  }, [instrumentData]);
  
  

  useEffect(() => {
    getDispatchIdList();
    getSrfsList();
  }, []);

  useEffect(() => {
    if(state?.instrumentIds){
      srfInstrumentsList();
    }
  }, [state?.instrumentIds]);

   const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async () => {
    let htmlString = `    
    <div id="responsivepdfheader">
      <div style="width: 100%; margin: 0 auto; display: flex; align-items: center; justify-content: space-between; page-break-inside: avoid;">
        <div style="text-align: left;">
          <h6 style="font-size: 21px;">Receivers Signature</h6>
          <br/>
          <h6 style="font-size: 21px;">Date</h6>
        </div>
        <div style="text-align: left;">
          <h6 style="font-size: 21px;"><b>For Chavanke Engineering Pvt. Ltd.</b></h6>
          <br/>
          <h6 style="font-size: 21px;">Authorised Sign</h6>
        </div>
      </div>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try{
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [98, 1, 109, 1],
        filename: `dispatch_${params.srfId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 2.5,
          letterRendering: true,
          useCORS: true,
        },
        image: { type: 'png', quality: 0.4 },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
          compress: true,
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

       // border add for contnet
       const borderWidth = 0.6;
       const leftMargin = 15;
       const borderX = leftMargin;
       const borderY = 95;
       const rightMargin = 595 - leftMargin * 0.95;
       pdf.setLineWidth(borderWidth);
       pdf.rect(borderX, borderY, rightMargin - leftMargin, 640);

      let image = await generateDynamicHeader(i, totalPages);
      pdf.addImage(image, 0, 53, 595, 60);

      image = await generateDynamicFooter();
      pdf.addImage(image, 0, 730, 595, 60);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 785, 595, 60);
      }
    }

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${state?.clientName}${state?.dcNumber ? `_${state?.dcNumber}` : ""}.pdf`;
    a.click();
    setDownlaodPdfLoading(false);
  } catch (error) {
    console.error('Error generating PDF:', error);
    setDownlaodPdfLoading(false);
  }
  };


  return (
    <div>
      <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          viewsdispatchGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
      <div style={{ padding: "5px 40px", marginBottom: "2rem" }}>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="srf-viewdispatch_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              id="srf-viewdispatch_download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                // generatePDF()
                generatePDF1({
                  state,
                   challanNo,
                   combinedData,
                   letterHead
                });
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </div>
      <div ref={printComponentRef} id="srf-viewdispatch_print">
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-3"></div>
            <div className="col-6 mt-2 text-center">
              <h5
                className="font-bold text-center"
                style={{ color: "#000066" }}
              >
                <b> DELIVERY CHALLAN</b>
              </h5>
                <span style={{fontSize:"14px"}}>Returnable / Non-Returnable</span>
            </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "13px", padding: "10px 0px" }}>
            <table
              style={{ width: "95%", margin: "0px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "20%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Customer's Name
                </th>
                <td
                  style={{
                    width: "35%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {state?.clientName}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                 Challan No
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "20%",
                    borderBottom: "none",
                  }}
                >
                  {challanNo || ""}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Address
                </th>
                <td
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {state?.address}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  Challan Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(state?.dispatchDate).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Contact Person Name
                </th>
                <td
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {state?.contactPersonName}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  Customer Challan No
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {state?.dcNumber}
               </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Email
                </th>
                <td
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {state?.emailId}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  Contact Number
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {state?.contact}
               </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                  }}
                >
                  CRF
                </th>
                <td
                  style={{
                    // width: "25%",
                    padding: "7px",
                    borderRight: "none",
                  }}
                >
                  {state?.serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                Customer Challan Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                  }}
                > {state?.dcDate ? moment(state?.dcDate).format("YYYY-MM-DD") : ""}</td>
              </tr>
            </table>
            <br />
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Sr. No.
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    ID/Serial No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Size / Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      // borderRight: "none",
                    }}
                  >
                   Quantity
                  </th>
                 
                </tr>
              </thead>
              {combinedData?.map((row, index) => (
                <tr key={index} style={{ lineHeight: "13px" }}>
                  <td
                    style={{
                      padding: "7px",
                      borderRight: "none",
                      borderTop: "none",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      padding: "7px",
                      borderRight: "none",
                      borderTop: "none",
                    }}
                  >
                    {row?.requestedDucName ? row?.requestedDucName : row?.instrumentName}
                  </td>
                  <td
                    style={{
                      padding: "7px",
                      borderRight: "none",
                      borderTop: "none",
                    }}
                  >
                  {row?.DUCID && row?.serialNumber  ? `${row?.DUCID} / ${row?.serialNumber}` : `${row?.DUCID} ${row?.serialNumber}`}  
                  </td>
                  <td
                    style={{
                      padding: "7px",
                      borderRight: "none",
                      borderTop: "none",
                    }}
                  >
                    {row?.ranges?.replaceAll("#", "")?.replaceAll("|", ", ")}
                  </td>
                
                  <td
                    style={{
                      padding: "7px",
                      borderTop: "none",
                    }}
                  >
                    {row?.quantity}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Delivery Challan"}
        />
      )}
    </div>
  );
}
