import autoTable from "jspdf-autotable";
import { customFontFamily, footerConfig, tableConfiguration, totalHeaderHeightWithCalibrationLableorNot } from "./srfsConfig";
import { layoutConfig, printSRFSConfiguration } from "./SRFSConstant";

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printSRFSConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printSRFSConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth) / 2;
  } else if (align === "right") {
    xPos =
      printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth - xSize;
  }

  printSRFSConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawBox = async (panels) => {
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [25, 25, 25, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize:
        panels.tableTextfontSize || printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      valign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;

  // let row = [
  //   {
  //     content: panels?.leftPanel.keys[0],
  //     rowSpan: rowSpan,
  //     styles: {
  //       font: customFontFamily?.boldFont,
  //       fontStyle: customFontFamily?.bold,
  //       valign: "middle",
  //       halign: "left",
  //     },
  //   },
  //   {
  //     content: panels?.leftPanel.values[0],
  //     rowSpan: rowSpan,
  //     styles: { valign: "middle", halign: "left" },
  //   },
  //   {
  //     content: panels?.rightPanel.keys[0],
  //     styles: {
  //       font: customFontFamily?.boldFont,
  //       fontStyle: customFontFamily?.bold,
  //       valign: "middle",
  //       halign: "left",
  //     },
  //   },
  //   {
  //     content: panels?.rightPanel.values[0],
  //     styles: { valign: "middle", halign: "left" },
  //   },
  // ];
  // tableRows.push(row);

  for (let i = 0; i < panels?.leftPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.leftPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.leftPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Add the final row with Address (colSpan 3)
  // let addressRow = [
  //   {
  //     content: panels?.leftPanel.keys[1], // "Client" key, to keep the layout
  //     styles: {
  //       font: customFontFamily?.boldFont,
  //       fontStyle: customFontFamily?.bold,
  //       valign: "middle",
  //       halign: "left",
  //     },
  //   },
  //   {
  //     content: panels?.leftPanel.values[1], // Address content
  //     colSpan: 3, // This will span across the last 3 columns
  //     styles: { valign: "middle", halign: "left" },
  //   },
  // ];

  // tableRows.push(addressRow);

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 80];

  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Render the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      halign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      // 2: { cellWidth: columnWidths[2] },
      // 3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 0;
};


export const drawForExtraSignTable = (initPage) => {
  const tableRows = [
    [
      {
        content: "Declaration by Laboratory: - Laboratory Activities falling under Accredited Scope in no way imply that the instrument/s calibrated is/are approved by NABL.",
        colSpan: 3,
        styles: {
          valign: "middle",
          halign: "left",
          fontSize: printSRFSConfiguration.fontSizes.h2,
          font: customFontFamily?.normalFont,
        },
      },
    ],
    [
      {
        content: "I/we declare that the information furnished above is correct to the best of my/our knowledge. I/we undertake to inform you at the earliest if any change in the details mentioned above.",
        styles: {
          valign: "middle",
          halign: "left",
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
      },
      {
        content: "We will entertain any special requirement other than this contract requested by you with calibration consignments given in written only",
        styles: {
          valign: "middle",
          halign: "left",
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
        colSpan:2
      },
    ],
    [
      {
        content: "\nSign (For Client)",
        styles: {
          valign: "middle",
          halign: "left",
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
      },
      {
        content: "\nSign (For Chavanke Engineering Pvt. Ltd.)",
        styles: {
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
        colSpan:2
      },
    ],
    [
      {
        content: "Date of Actual Work Completion (for Site Calibration)/ Date of Dispatch of Calibration item (for In-House Calibration):-",
        styles: {
          valign: "middle",
          halign: "left",
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
      },
      {
        content: "\n\nSign (For Client)",
        styles: {
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
      },
      {
        content: "\n\nSign (For Chavanke Engineering Pvt. Ltd.)", // New Column
        styles: {
          font: customFontFamily?.normalFont,
          fontSize: printSRFSConfiguration.fontSizes.h3,
        },
      },
    ],
  ];

  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [50, 18, 32]; // Adjusted for 3 columns
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal + 10,
    margin: {
      top: totalHeaderHeightWithCalibrationLableorNot.total,
      right: printSRFSConfiguration.margin,
      left: printSRFSConfiguration.margin,
      bottom:
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight +
        footerConfig.footerandbodygap,
    },
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h4,
      font: customFontFamily?.normalFont,
      cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] }, // New Column
    },
    pageBreak: "avoid",
    didDrawPage: (data) => {
      if (data.cursor.y < totalHeaderHeightWithCalibrationLableorNot.total + 70) {
        initPage();
      }
    },
  });
};
