import { BASE_URL } from "../../global";
import axiosWithToken from "./axiosTokenConfig";

export async function allSettings(clientID,companyHierarchy) {

  try {
    const generalSettingsPromise = axiosWithToken
      .get(BASE_URL + `settings?_where=(status,ne,-1)`)
      .then((res) => res.data)
      .catch((err) => {
        console.log("setting fetch error : ", err);
        return [];
      });

    let clientSetting = [];
    if (clientID) {
      clientSetting = await axiosWithToken
        .get(
          BASE_URL +
            `clientSettings?_where=(status,ne,-1)~and(clientId,eq,${clientID})${companyHierarchy ? `~and(companyHierarchy,eq,${companyHierarchy})`: `~and(companyHierarchy,is,null)`}`
        )
        .then((res) => res.data)
        .catch((err) => {
          console.log("setting fetch error : ", err);
          return [];
        });
    }

        const [generalSettings, clientSettings] = await Promise.all([generalSettingsPromise, clientSetting]);

        const mergeSettings = (general, client) => {
            const settingsMap = new Map();

            // Add general settings to map
            general.forEach(setting => {
                settingsMap.set(setting.keyName, setting);
            });

            // Update map with client settings, overriding if keyName exists
            client.forEach(setting => {
                settingsMap.set(setting.keyName, setting);
            });

            // Return combined array without duplicates based on keyName
            return Array.from(settingsMap.values());
        };

        // Merge the arrays
        const combinedSettings = mergeSettings(generalSettings, clientSettings);
        return combinedSettings;
    } catch (error) {
        console.error("Error in allSettings:", error);
        return [];
    }
}
